<template>
  <v-dialog v-model="dialog" class="dialog" max-width="550">
    <v-card class="card">
      <v-form>
        <h2 class="text-center">Add Card</h2>
        <template>
          <div class="stripe-card number">
            <div id="cardNumber"></div>
          </div>
        </template>
        <v-row>
          <v-col>
            <div class="stripe-card element" id="cardExpire"></div>
          </v-col>
          <v-col>
            <div class="stripe-card element" id="cardCVC"></div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right mt-2 mb-4" cols="12">
            <v-btn
              class="mr-5"
              style="box-shadow: none; background-color: unset !important"
              @click="$emit('close')"
              >cancel
            </v-btn>
            <v-btn class="button save-btn" height="42" @click="handleSaveCard"
              >Save
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  async mounted() {
    this.setUpStripe();
  },
  name: "AddNewCardDialog",
  props: {
    dialog: Boolean,
  },
  methods: {
    setUpStripe: function () {
      let stripe = window.Stripe(
        "pk_test_51JebUOFhWfRcXpLSbJJ3JG4rN9khPsmUYv39DlaDsj4W7idvGoVVPBRAYddmBbtaYGATeIXR6DdG1cF4yZWFUFO800aHZzONgW"
      );
      this.stripe = stripe;
      this.elements = stripe.elements();
      this.cardCvc = this.elements.create("cardCvc");
      this.cardExpiry = this.elements.create("cardExpiry");
      this.cardNumber = this.elements.create("cardNumber", {
        showIcon: true,
        placeholder: "Card number",
        style: {
          base: {
            backgroundColor: "transparent",
            iconColor: "#262626",
            fontFamily: "Raleway medium, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
          },
          invalid: {
            iconColor: "Red",
            color: "Red",
          },
        },
      });
      this.cardCvc.mount("#cardCVC");
      this.cardExpiry.mount("#cardExpire");
      this.cardNumber.mount("#cardNumber");
    },
    async handleSaveCard() {
      await this.purchase();
    },
    purchase: async function () {
      let valid = true;
      if (valid) {
        const result = await this.stripe.createPaymentMethod({
          type: "card",
          card: this.cardNumber,
        });

        if (result.error) {
          this.$notify({
            group: "foo",
            title: result.error.message || "Something went wrong!",
            type: "error",
          });
          return false;
        }
        this.paymentMethodId = result.paymentMethod?.id;
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.stripe-card {
  padding: 8px 12px;
  margin-right: 36px;
  margin-bottom: 26px;
  border: solid 1px rgb(154, 152, 152);
  line-height: 1.5;
  height: 60px;
  align-items: stretch;
  min-height: 56px;
  cursor: text;
  border-radius: 15px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &.number {
    margin-top: 10px;
  }
}
</style>
